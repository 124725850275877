import axios from 'axios';
import { BASE_URL } from '../constants/config';

const AuthService = {
    authLocal: async function(payload:any){
        try{
            const response = await axios.post(`${BASE_URL}/auth/local`, payload, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    getLoginSuccess: async function(){
        try{
            const response = await axios.get(`${BASE_URL}/auth/login/success`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
}

export default AuthService;