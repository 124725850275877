import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import { getLoginSuccess } from '../actions';

import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
  } from "../actionTypes";

const initialState = {
    isLoggedIn: false,
    user: null
};

const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      // omit existing reducers here
    },
    extraReducers(builder) {
        builder
            .addCase(getLoginSuccess.pending, (state:any, action) => {
                state.status = 'loading';
            })
            .addCase(getLoginSuccess.fulfilled, (state:any, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.account = action.payload.user?.account;
                state.isLoggedIn = action.payload.success;
            })
            .addCase(getLoginSuccess.rejected, (state:any, action) => {
                state.status = 'failed';
                state.error = action.error.message;
        })
    }
})

export default usersSlice.reducer;
/* 
export default function foo(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS: 
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGOUT:
                return {
                    ...state,
                    isLoggedIn: false,
                    user: null,
                };
        default:
            return state;
    }
} */
