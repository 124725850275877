import { grey } from '@mui/material/colors';

export function overrides(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                },
                html: {
                    margin: 0,
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    WebkitOverflowScrolling: 'touch',
                },
                body: {
                    margin: 0,
                    padding: 0,
                    width: '100%',
                    height: '100%',
                },
                '#root': {
                    width: '100%',
                    height: '100%',
                },
                input: {
                    '&[type=number]': {
                        MozAppearance: 'textfield',
                    '&::-webkit-outer-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none',
                        },
                    '&::-webkit-inner-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none',
                        },
                    },
                },
                img: {
                    maxWidth: '100%',
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    borderRadius: "4px"
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    borderRight: "1px dashed rgba(145, 158, 171, 0.24)",
                    color: "rgb(99, 115, 129)"
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    backgroundColor: "rgb(255, 255, 255)",
                    border: "none",
                    boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    borderRadius: "16px",
                    padding: "24px"
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                body: {
                    borderBottom: `1px dashed ${grey[300]}`
                },
                head: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.background.neutral
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0
                },
            },
        }
    }
}