import { 
    SHOP_LIST,
    SHOP_IMAGES_LIST 
} from "../actionTypes";

const initialState = {
    shopList: []
};

export default function foo(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SHOP_LIST: {
            return {
                ...state,
                shopList: payload.shops
            };
        }
        case SHOP_IMAGES_LIST: {
            return {
                ...state,
                shopImagesUrl: payload.shopImagesUrl
            };
        }
        default:
            return state;
    }
}
