import axios from 'axios';
import { BASE_URL } from '../constants/config';

const ShopService = {
    getShops: async function(){
        try{
            const response = await axios.get(`${BASE_URL}/api/shops`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    getShop: async function(id:any){
        try{
            const response = await axios.get(`${BASE_URL}/api/shops/${id}`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    createShop: async function(payload:any){
        try{
            const response = await axios.post(`${BASE_URL}/api/shops`, payload, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    updateShop: async function(payload:any){
        try{
            const response = await axios.put(`${BASE_URL}/api/shops/${payload._id}`, { shop: payload }, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    deleteShop: async function(id:any){
        try{
            const response = await axios.delete(`${BASE_URL}/api/shops/${id}`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    }
}

export default ShopService;