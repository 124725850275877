import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import palette from './Palette';
import { overrides } from './overrides';

export default function ThemeProvider({ children }:any) {
    const themeOptions = useMemo(
        () => ({
            palette,
            shape: { borderRadius: 6 }
        }),
        []
    );

    const theme = createTheme(themeOptions);
    theme.components = overrides(theme);

    return (
        <MUIThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MUIThemeProvider>
    );
}


/* let theme = createTheme({
    palette: {
        background: {
            default: "#FFF"
        }
    },
    typography: {
        fontFamily: "Public Sans, sans-serif"
    }
});

theme = createTheme(theme, {
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgb(255, 255, 255)",
                    border: "none",
                    boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    borderRadius: "16px",
                    padding: "24px"
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    borderRight: "1px dashed rgba(145, 158, 171, 0.24)",
                    color: "rgb(99, 115, 129)"
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#fff",
                    color: "#5E6278",
                    height: '70px',
                    boxShadow: "0px 10px 30px 0px rgba(82, 63, 105, 0.05)"
                }
            }
        }
    }
});

export default theme; */