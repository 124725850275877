import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { bgBlur } from '../../src/assets/theme/css';
import { alpha } from '@mui/material/styles';

/* import { useResponsive } from 'src/hooks/use-responsive';



import Iconify from 'src/components/iconify'; */

/* import Searchbar from './common/searchbar'; */
import { NAV, HEADER } from './config-layout';
import LanguagePopover from './common/language-popover';
import AccountPopover from './common/account-popover';
/* import AccountPopover from './common/account-popover';
import NotificationsPopover from './common/notifications-popover'; */

// ----------------------------------------------------------------------

export default function Header({ user, onOpenNav }) {
    const theme = useTheme();
    const account = {
        email: user?.email,
        photoURL: user?.profile?.picture,
        displayName: user?.profile?.firstname+" "+user?.profile?.lastname
    }

    /* const lgUp = useResponsive('up', 'lg'); */

    const renderContent = (
        <>
            {/* {!lgUp && (
                <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>
            )}

        <Searchbar /> */}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={1}>
            <LanguagePopover />
            {/* <NotificationsPopover /> */}
            <AccountPopover account={account}/>
        </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                backgroundColor: alpha(theme.palette.background.default, 0.8),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                })
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};