import { PREFIX_APP } from '../../constants/config';

import { styled } from '@mui/material/styles';
import List, { ListProps } from '@mui/material/List';
import Paper, { PaperProps } from '@mui/material/Paper';
import MuiList from '@mui/material/List';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MuiBox from '@mui/material/Box';

export const classes = {
    root: `${PREFIX_APP}-root`
}

export const AppDrawer = styled(MuiBreadcrumbs)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        display: "none"
    },
    [`&.${classes.root}`]: {
    }
}));

export const SidebarList = styled(List)<ListProps>(({ theme }) => ({
    width: 280,
    padding: '0 16px',
    "& .MuiList-root": {
        padding: "0 16px"
    },
    "& .MuiListSubheader-root": {
        textTransform: "uppercase",
        fontWeight: 700,
        fontSize: "11px",
        padding: "24px 16px 8px",
        lineHeight: 1.5
    },
    "& .MuiLink-root": {
        textDecoration: "none",
        color: "rgb(99, 115, 129)",
        "&.active": {
            color: "rgb(0, 171, 85)",
            "& .MuiListItemButton-root": {
                backgroundColor: "rgba(0, 171, 85, 0.08)",
                "& .MuiListItemIcon-root": {
                    color: "rgb(0, 171, 85)",
                }
            }
        },
    },
    "& .MuiListItemButton-root": {
        borderRadius: "8px",
        margin: "0px 0px 4px",
        "&:hover": {
        },
        "& .MuiListItemText-primary": {
            fontSize: "14px",
            fontWeight: "600"
        }
    },
    "& .MuiCollapse-wrapperInner a": {
        margin: "0px"
    }
})) as any;

export const CustomPaper = styled(Paper)<PaperProps>(({ theme }) => ({
    backgroundColor: "rgb(255, 255, 255)",
    border: "none",
    boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
    borderRadius: "16px",
    padding: "24px"
})) as any;

/* export const AppWrapper = styled(MuiBox)(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: "flex",
        marginLeft: "250px",
        [theme.breakpoints.down("md")]: {
            marginLeft: "0px"
        }
    }
})); */