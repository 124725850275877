import React from 'react';
import { createTheme, styled } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Router from './routes/sections';
import { HelmetProvider } from 'react-helmet-async';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

import { Provider } from "react-redux";
import store from "./redux/store";

import AdminLayout from './layouts/AdminLayout';
import ThemeProvider from './assets/theme/Theme';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18n_translations } from "./constants/i18n";

const firebaseConfig = {
    apiKey: "AIzaSyD9vVHxnJVM3-CDED427vitxr38LZcyjzA",
    authDomain: "sailshappy-2e6e4.firebaseapp.com",
    projectId: "sailshappy-2e6e4",
    storageBucket: "sailshappy-2e6e4",
    messagingSenderId: "820831304333",
    appId: "1:820831304333:web:147d9980f19d52c7ae00c4",
    measurementId: "G-L0QVF5B8FS"
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: i18n_translations,
        lng: "it", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

function App() {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    getPerformance(app);

    return (
        <HelmetProvider>
            <ThemeProvider>
                <Provider store={store}>
                    {/* <BrowserRouter>
                        <Routes>
                            <Route path="/*" element={<AdminLayout />} />
                        </Routes>
                    </BrowserRouter> */}
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </Provider>,
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
