import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';

import {
    Avatar,
    Box,
    Divider, 
    IconButton,
    MenuItem,
    Popover,
    Typography,
} from '@mui/material';
import { BASE_URL } from '../../constants/config';

export default function AccountPopover({ account }) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);

    const MENU_OPTIONS = [
        {
            label: t('Home'),
            icon: 'eva:home-fill',
            navigate: '/dashboard'
        },
        {
            label: t('Profile'),
            icon: 'eva:person-fill',
            navigate: '/profile'
        },
        {
            label: t('Settings'),
            icon: 'eva:settings-2-fill',
            navigate: '/dashboard'
        }
    ];

    /* const account = {
        email: "valeriodidona@gmail.com",
        photoURL: "test.png",
        displayName: "Valerio di Dona"
    } */

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleNavigateAndClose = (nav) => {
        setOpen(null);
        navigate(nav);
    };

    const logout = () => {
        window.open(`${BASE_URL}/auth/logout`, "_self");
    };

  return (
    <>
        <IconButton
            onClick={handleOpen}
            sx={{
                width: 40,
                height: 40,
                background: (theme) => alpha(theme.palette.grey[500], 0.08)
            }}
        >
            <Avatar
                src={account.photoURL}
                alt={account.displayName}
                sx={{
                    width: 36,
                    height: 36,
                    border: (theme) => `solid 2px ${theme.palette.background.default}`,
                }}
            >
                {account.displayName.charAt(0).toUpperCase()}
            </Avatar>
        </IconButton>

        <Popover
            open={!!open}
            anchorEl={open}
            onClose={() => setOpen(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                p: 0,
                mt: 1,
                ml: 0.75,
                width: 200,
                },
            }}
            >
            <Box sx={{ my: 1.5, px: 2 }}>
                <Typography variant="subtitle2" noWrap>{account.displayName}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{account.email}</Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {MENU_OPTIONS.map((option) => (
                <MenuItem key={option.label} onClick={() => handleNavigateAndClose(option.navigate)}>
                    {option.label}
                </MenuItem>
            ))}

            <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

            <MenuItem
                disableRipple
                disableTouchRipple
                onClick={logout}
                sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
            >
                {t('Logout')}
            </MenuItem>
        </Popover>
    </>
  );
}