import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { getBoatById } from "../redux/selectors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import { 
    Box,
    Collapse,
    Divider,
    FormControl,
    InputLabel,
    Link,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon, 
    ListSubheader,
    MenuItem,
    Select
}from '@mui/material';

import { Icon } from '@iconify/react';
import List, { ListProps } from '@mui/material/List';

import { SidebarList } from '../assets/theme/AdminComponentsStyles';
import { Link as LinkRouter, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const menuColor = '#9899ac';

const Sidebar = () => {
    const {t} = useTranslation();
    const boat = useSelector((state:any) => state.boats);
    const [open, setOpen] = React.useState({} as any);

    const navConfig = [
        {
            title: t("Dashboard"),
            path: "/dashboard",
            icon: "material-symbols:dashboard"
        },
        {
            title: t("Calendar"),
            path: "/calendar",
            icon: "solar:calendar-bold-duotone"
        },
        {
            title: t("Boats"),
            path: '/boats',
            icon: 'mingcute:sailboat-fill'
        },
        {
            title: t("Members"),
            path: '/members',
            icon: 'solar:users-group-two-rounded-bold-duotone'
        },
        {
            title: t("Crews"),
            path: '/crews',
            icon: 'solar:t-shirt-bold'
        },
        {
            title: t("Activities"),
            path: '/activities',
            icon: 'solar:calendar-minimalistic-bold-duotone'
        },
        {
            title: t("Expenses"),
            path: '/expenses',
            icon: 'iconamoon:invoice-duotone'
        },
    ]

    const handleMenu = (index:any) => {
        setOpen(((prevState:any) => ({...prevState, [index]: !prevState[index]})));
    };
    
    return (
        <>
            <SidebarList 
                disablePadding
            >
                <ListSubheader id="nested-list-subheader">
                    {t("General")}
                </ListSubheader>
                {/* <pre>
                    {JSON.stringify(boat)}
                </pre> */}

                {
                    navConfig.map((item,index) => {
                        return (
                            <Link key={index} component={NavLink} to={item.path} end>
                                <ListItemButton>
                                    <ListItemIcon sx={{color: menuColor}}>
                                        <Icon icon={item.icon} width={24} />
                                    </ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItemButton>
                            </Link>
                        )     
                    })
                }
            </SidebarList>
        </>
    );
}

/* const mapStateToProps = state => {
    const boat = getBoatById(state, "65300774b904c6aa46c074a0");
    return { boat };
    //   const allTodos = getTodos(state);
    //   return {
    //     todos:
    //       visibilityFilter === VISIBILITY_FILTERS.ALL
    //         ? allTodos
    //         : visibilityFilter === VISIBILITY_FILTERS.COMPLETED
    //           ? allTodos.filter(todo => todo.completed)
    //           : allTodos.filter(todo => !todo.completed)
    //   };
}; */

/* export default connect(mapStateToProps)(Sidebar); */

export default Sidebar;