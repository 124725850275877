import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Icon } from '@iconify/react';
import {
    Box,
    IconButton,
    MenuItem,
    Popover    
} from '@mui/material';

// ----------------------------------------------------------------------

const LANGS = [
    {
        value: 'it',
        label: 'Italian',
        icon: 'emojione-v1:flag-for-italy',
    },
    {
        value: 'en',
        label: 'English',
        icon: 'emojione-v1:flag-for-united-kingdom',
    }
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const {t,i18n} = useTranslation()
    const [open, setOpen] = useState(null);
    const [lang, setLang] = useState(LANGS[0]);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (option) => {
        setLang(option);
        changeLanguageHandler(option.value)
        setOpen(null);
    };

    const changeLanguageHandler = (lang) => {
       i18n.changeLanguage(lang)
    }

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40
                }}
            >
                <Icon icon={lang.icon} />
            </IconButton>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                    p: 0,
                    mt: 1,
                    ml: 0.75,
                    width: 180,
                    },
                }}
            >
                {LANGS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === lang.value}
                        onClick={() => handleClose(option)}
                        sx={{ typography: 'body2', py: 1 }}
                    >
                        <Box component={Icon} icon={option.icon} sx={{ width: 28, mr: 2 }} />
                        {option.label}
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
}