import axios from 'axios';
import { BASE_URL } from '../constants/config';

const BoatService = {
    getBoats: async function(){
        try{
            const response = await axios.get(`${BASE_URL}/api/boats`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    getBoatsByAccount: async function(account){
        try{
            const response = await axios.get(`${BASE_URL}/api/boats/account/${account}`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    getBoat: async function(id:any){
        try{
            const response = await axios.get(`${BASE_URL}/api/boats/${id}`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    /* createBoatFake: async function(payload:any){
        try{
            const response = await axios.post(`${BASE_URL}/api/boats_fake`, payload, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    }, */
    createBoat: async function(payload:any){
        try{
            const response = await axios.post(`${BASE_URL}/api/boats`, payload, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    updateBoat: async function(id:any,payload:any){
        try{
            const response = await axios.put(`${BASE_URL}/api/boats/${id}`, payload, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    },
    deleteBoat: async function(id:any){
        try{
            const response = await axios.delete(`${BASE_URL}/api/boats/${id}`, { withCredentials: true });
            return response.data;
        } catch (error){
            throw error;
        }
    }
}

export default BoatService;