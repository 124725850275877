export const i18n_translations = {
    en: {
        translation: {
            "Add": "Add",
            "Add Activity": "Add Activity",
            "Add Boat": "Add Boat",
            "Add Expense": "Add Expense",
            "Add Other Expense": "Add Other Expense",
            "Add Other Activity": "Add Other Activity",
            "Activities": "Activities",
            "Activity": "Activity",
            "Activity Count": "Activity Count",
            "Activity Created": "Activity Created",
            "Activity Updated": "Activity Updated",
            "All Expenses": "All Expenses",
            "anchoringAndMooring": "Anchoring and Mooring",
            "Anchoring and Mooring": "Anchoring and Mooring",
            "Are you sure you want to delete the expense": "Are you sure you want to delete the expense",
            "Beam": "Beam",
            "Billing": "Billing",
            "Boat": "Boat",
            "boatTransfer": "Boat Transfer",
            "Boats": "Boats",
            "Boat Created": "Barca Created",
            "Boat Name": "Boat Name",
            "Boat Equipment": "Boat Equipment",
            "Boat Expenses": "Boat Expenses",
            "Boat Expenses To Pay": "Boat Expenses To Pay",
            "Boat Maintenance": "Boat Maintenance",
            "boatMaintenance": "Boat Maintenance",
            "Boat Purchase": "Boat Purchase",
            "Boat safety equipment": "Boat safety equipment",
            "Boat Transfer": "Boat Transfer",
            "Calendar": "Calendar",
            "Category": "Category",
            "Company": "Company",
            "completed": "Completed",
            "Completed": "Completed",
            "Construction Year": "Construction Year",
            "Correctly": "Correctly",
            "Crew": "Crew",
            "Crews": "Crews",
            "Crew Members": "Crew Member",
            "Date": "Date",
            "Date of Birth": "Date of Birth",
            "daySailing": "Day Sailing",
            "Day Sailing": "Day Sailing",
            "Delete": "Delete",
            "Delete Expense?": "Delete Expense?",
            "Description": "Description",
            "Draught": "Draught",
            "Edit": "Edit",
            "Edit Activity": "Edit Activity",
            "electronicsAndNavigation": "Electronics and navigation",
            "Electronics and navigation": "Electronics and navigation",
            "Engine": "Engine",
            "Engineperformance": "Engine Performance",
            "Expdate": "Expense Date",
            "Expense": "Expense",
            "Expense Created": "Expense Created",
            "Expense Updated": "Expense Updated",
            "Expenses": "Expenses",
            "Expense Count": "Expense Count",
            "Fin Keel": "Fin Keel",
            "Firstname": "Firstname",
            "Forgot password": "Forgot password",
            "Fueltype": "Fuel Type",
            "General": "General",
            "GRP": "GRP",
            "inProgress": "In Progress",
            "In Progress": "In Progress",
            "Insurance": "Insurance",
            "Item": "Item",
            "Keeltype": "Keel Type",
            "Lastname": "Lastname",
            "Length": "Length",
            "Lighting and electrical": "Lighting and electrical",
            "List": "List",
            "Long Keel": "Long Keel",
            "Manufacturer": "Manufacturer",
            "Material": "Material",
            "Member": "Member",
            "Members": "Members",
            "Name": "Name",
            "notStarted": "Not Started",
            "notPayed": "Not Payed",
            "Not Payed": "Not Payed",
            "Not Started": "Not Started",
            "payed": "Payed",
            "Place": "Place",
            "plumbingAndSanitary": "Plumbing and Sanitary",
            "Plumbing and Sanitary": "Plumbing and Sanitary",
            "Profile": "Profile",
            "Quantity": "Quantity",
            "Payed": "Payed",
            "Payment Status": "Payment Status",
            "Paymentstatus": "Payment Status",
            "Phone": "Phone",
            "Price": "Price",
            "Resume": "Resume",
            "Safety": "Safety",
            "sailingEquipment": "Sailing Equipment",
            "Sailing equipment": "Sailing Equipment",
            "Save": "Save",
            "Security": "Security",
            "Search Expense": "Search Expense",
            "Settings": "Settings",
            "Shipowner": "Shipowner",
            "Shipyard Works": "Shipyard Works",
            "Shop": "Negozio",
            "Sign in to ": "Sign in to ",
            "Steering": "Steering",
            "tenderAndEngine": "Tender and Engine",
            "Tender and Engine": "Tender and Engine",
            "Tiller Steering": "Tiller Steering",
            "Total": "Total",
            "Trip": "Viaggio",
            "Type": "Type",
            "View Activity": "Vedi Activity",
            "View Expense": "View Expense",
            "Year": "Year",
            "Wheel Control": "Wheel Control"
        }
    },
    it: {
        translation: {
            "Add": "Aggiungi",
            "Add Activity": "Aggiungi Attività",
            "Add Boat": "Aggiungi Barca",
            "Add Expense": "Aggiungi Spesa",
            "Add Other Expense": "Aggiungi un'altra Spesa",
            "Add Other Activity": "Aggiungi un'altra Attività",
            "Activities": "Attività",
            "Activity": "Attività",
            "Activity Count": "Contatore Attività",
            "Activity Created": "Attività Creata",
            "Activity Updated": "Attività aggiornata",
            "All": "Tutto",
            "All Expenses": "Tutte le spese",
            "anchoringAndMooring": "Ancoraggio e Ormeggio",
            "Anchoring and Mooring": "Ancoraggio e Ormeggio",
            "Are you sure you want to delete the expense": "Sei sicuro di voler eliminare la spesa",
            "Beam": "Larghezza",
            "Billing": "Fatturazione",
            "Boat": "Barca",
            "boatTransfer": "Trasferimento Barca",
            "Boats": "Barche",
            "Boat Created": "Barca aggiunta",
            "Boat Name": "Nome Barca",
            "Boat Equipment": "Attrezzatura da Barca",
            "Boat Expenses": "Spese Barca",
            "Boat Expenses To Pay": "Spese Barca da pagare",
            "Boat Maintenance": "Manutenzione Barca",
            "boatMaintenance": "Manutenzione Barca",
            "Boat Purchase": "Acquisto Barca",
            "Boat safety equipment": "Dotazioni di Sicurezza Barca",
            "Boat Transfer": "Trasferimento Barca",
            "Calendar": "Calendario",
            "Category": "Categoria",
            "Company": "Società",
            "completed": "Completata",
            "Completed": "Completata",
            "Construction Year": "Anno di Costruzione",
            "Correctly": "Correttamente",
            "Crew": "Equipaggio",
            "Crews": "Equipaggi",
            "Crew Members": "Membri dell'equipaggio",
            "Date": "Data",
            "Date of Birth": "Data di Nascita",
            "daySailing": "Giornata a Vela",
            "Day Sailing": "Giornata a Vela",
            "Delete": "Elimina",
            "Delete Expense?": "Eliminare Spesa?",
            "Description": "Descrizione",
            "Dimensions": "Dimensioni",
            "Draught": "Pescaggio",
            "Edit": "Modifica",
            "Edit Activity": "Modifica Attività",
            "electronicsAndNavigation": "Elettronica e Navigazione",
            "Electronics and navigation": "Elettronica e Navigazione",
            "Engine": "Motore",
            "Engineperformance": "Potenza del Motore",
            "Expdate": "Data Acquisto",
            "Expense": "Spesa",
            "Expense Created": "Spesa Creata",
            "Expense Updated": "Spesa Aggiornata",
            "Expenses": "Spese",
            "Expense Count": "Contatore Spese",
            "Fin Keel": "Chiglia Corta",
            "Firstname": "Nome",
            "Forgot password": "Password dimenticata",
            "Fueltype": "Carburante",
            "General": "Generale",
            "GRP": "Vetroresina VTR",
            "Hi": "Ciao",
            "inProgress": "In Progress",
            "In Progress": "In Progress",
            "Insurance": "Assicurazione",
            "Item": "Oggetto",
            "Keel Type": "Tipo di Chiglia",
            "Keeltype": "Tipo di Chiglia",
            "Lastname": "Cognome",
            "Length": "Lunghezza",
            "Lighting and electrical": "Illuminazione ed Elettricità",
            "List": "Lista",
            "Long Keel": "Chiglia Lunga",
            "Manufacturer": "Cantiere",
            "Material": "Materiale",
            "Member": "Membro",
            "Members": "Membri",
            "Name": "Nome",
            "notStarted": "Non Iniziata",
            "notPayed": "Non Pagato",
            "Not Payed": "Non Pagato",
            "Not Started": "Non Iniziata",
            "payed": "Pagato",
            "Payment Status": "Stato Pagamento",
            "Paymentstatus": "Stato Pagamento",
            "Phone": "Telefono",
            "Place": "Luogo",
            "plumbingAndSanitary": "Idraulica e Sanitari",
            "Plumbing and Sanitary": "Idraulica e Sanitari",
            "Profile": "Profilo",
            "Quantity": "Quantità",
            "Payed": "Pagato",
            "Price": "Prezzo",
            "Resume": "Riepilogo",
            "Safety": "Sicurezza",
            "sailingEquipment": "Attrezzatura per Barca a Vela",
            "Sailing equipment": "Attrezzatura per Barca a Vela",
            "Save": "Salva",
            "Security": "Sicurezza",
            "Search Expense": "Ricerca Spesa",
            "Settings": "Impostazioni",
            "Shipowner": "Armatore",
            "Shipyard Works": "Lavori Cantiere",
            "Shop": "Negozio",
            "Sign in to ": "Accedi a ",
            "Status": "Stato",
            "Steering": "Timone",
            "tenderAndEngine": "Tender e Motore",
            "Tender and Engine": "Tender e Motore",
            "Tiller Steering": "Timone a Barra",
            "Total": "Totale",
            "Trip": "Viaggio",
            "Type": "Tipologia",
            "View Activity": "Vedi Attività",
            "View Expense": "Vedi Spesa",
            "Year": "Anno",
            "welcome back": "bentornato",
            "Wheel Control": "Timone a Ruota"
        }
    }
}