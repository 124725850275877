import { BOAT_LIST, BOAT_EQUIPMENT_LIST, BOAT_MANUFACTURER_LIST, BOAT_SELECTED } from "../actionTypes";

const initialState = {
    boatEquipmentList: [],
    boatList: [],
    boatManufacturerList: [],
    boatSelected: "test"
};

export default function foo(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case BOAT_LIST: {
            return {
                ...state,
                boatList: payload.boats
            };
        }
        case BOAT_EQUIPMENT_LIST: {
            return {
                ...state,
                boatEquipmentList: payload.boatEquipments
            };
        }
        case BOAT_MANUFACTURER_LIST: {
            return {
                ...state,
                boatManufacturerList: payload.boatManufacturers
            };
        }
        case BOAT_SELECTED: {
            return {
                ...state,
                boatSelected: payload.boat
            };
        }
        default:
            return state;
    }
}
