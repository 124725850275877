import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';

import { 
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    BOAT_EQUIPMENT_LIST,
    BOAT_LIST,
    BOAT_SELECTED, 
    SHOP_LIST,
    BOAT_MANUFACTURER_LIST,
    SHOP_IMAGES_LIST
} from "./actionTypes";

import AuthService from "../services/AuthService";
import BoatService from "../services/BoatService";
import ListOfValuesService from "../services/ListOfValuesService";
import ShopService from "../services/ShopService";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";

/* let nextTodoId = 0;

export const addTodo = content => ({
  type: ADD_TODO,
  payload: {
    id: ++nextTodoId,
    content
  }
});

export const toggleTodo = id => ({
  type: TOGGLE_TODO,
  payload: { id }
}); */

/* export const setFilter = filter => ({ type: SET_BOAT, payload: { filter } });
 */

export const getLoginSuccess = createAsyncThunk('user/loginSuccess', async () => {
    const response = await AuthService.getLoginSuccess();
    return response
});

/* export const loginSuccess = () => (dispatch) => {
    return AuthService.getLoginSuccess().then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });
    
            return Promise.resolve();
        },
        (error) => {
            const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
    
            dispatch({
                type: LOGIN_FAIL,
            });
    
            return Promise.reject();
        }
    );
}; */

export const actionGetBoat = (id:any) => (dispatch) => {
    return BoatService.getBoat(id).then(
        (data) => {
            dispatch({
                type: BOAT_SELECTED,
                payload: { boat: data },
            });
    
            return Promise.resolve();
        },
        (error) => {
            const message = error.toString();
            console.log(message);      
            return Promise.reject();
        }
    );
};

export const boatList = (account) => (dispatch) => {
    return BoatService.getBoatsByAccount(account).then(
        (data) => {
            dispatch({
                type: BOAT_LIST,
                payload: { boats: data },
            });
    
            return Promise.resolve();
        },
        (error) => {
            const message = error.toString();
            console.log(message);      
            return Promise.reject();
        }
    );
};

export const boatEquipmentList = () => (dispatch) => {
    return ListOfValuesService.getBoatEquipments().then(
        (data) => {
            dispatch({
                type: BOAT_EQUIPMENT_LIST,
                payload: { boatEquipments: data },
            });
    
            return Promise.resolve();
        },
        (error) => {
            const message = error.toString();
            console.log(message);      
            return Promise.reject();
        }
    );
};

export const boatManufacturerList = () => (dispatch) => {
    return ListOfValuesService.getManufacturers().then(
        (data) => {
            dispatch({
                type: BOAT_MANUFACTURER_LIST,
                payload: { boatManufacturers: data },
            });
    
            return Promise.resolve();
        },
        (error) => {
            const message = error.toString();
            console.log(message);      
            return Promise.reject();
        }
    );
};

export const shopList = () => (dispatch) => {
    return ShopService.getShops().then(
        (data) => {
            dispatch({
                type: SHOP_LIST,
                payload: { shops: data },
            });
    
            return Promise.resolve();
        },
        (error) => {
            const message = error.toString();
            console.log(message);      
            return Promise.reject();
        }
    );
};

export const shopImagesUrl = () => (dispatch) => {
    const storage = getStorage();
    var listRef = ref(storage, `Shops/`);
    var downloadURLObj = {};
    var downloadURLPromises = [] as any[];
    return listAll(listRef)
        .then
            (async (res) => {
                res.items.forEach(imgRef => downloadURLPromises.push(getDownloadURL(imgRef)));
                const downloadURLsPromiseAllResult = await Promise.all(downloadURLPromises);

                for(var index in res.items){
                    downloadURLObj[res.items[index].name] = downloadURLsPromiseAllResult[index];
                }
                
                dispatch({
                    type: SHOP_IMAGES_LIST,
                    payload: { shopImagesUrl: downloadURLObj },
                });
        
                return Promise.resolve();
            }),
            (error) => {
                const message = error.toString();
                console.log(message);      
                return Promise.reject();
            }
};

