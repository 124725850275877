import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import AdminLayout from '../layouts/AdminLayout';

export const DashboardPage = lazy(() => import('../views/dashboard/Dashboard'));
export const ActivitiesPage = lazy(() => import('../views/activities/Activities'));
export const AddActivityPage = lazy(() => import('../views/activities/AddActivity'));
export const ActivityPage = lazy(() => import('../views/activities/Activity'));
export const EditActivityPage = lazy(() => import('../views/activities/EditActivity'));
export const BoatsPage = lazy(() => import('../views/boats/Boats'));
export const AddBoatPage = lazy(() => import('../views/boats/AddBoat'));
export const BoatPage = lazy(() => import('../views/boats/Boat'));
export const EditBoatPage = lazy(() => import('../views/boats/EditBoat'));
export const CalendarPage = lazy(() => import('../views/calendar/calendar'));
export const AddCrew = lazy(() => import('../views/crews/AddCrew'));
export const CrewPage = lazy(() => import('../views/crews/Crews'));
export const ExpensesPage = lazy(() => import('../views/expenses/Expenses'));
export const AddExpensePage = lazy(() => import('../views/expenses/AddExpense'));
export const ExpensePage = lazy(() => import('../views/expenses/Expense'));
export const EditExpensePage = lazy(() => import('../views/expenses/EditExpense'));
export const CategoryExpensesPage = lazy(() => import('../views/expenses/CategoryExpenses'));
export const LoginPage = lazy(() => import('../views/auth/Login'));
export const MemberPage = lazy(() => import('../views/members/Members'));
export const RegisterPage = lazy(() => import('../views/auth/Register'));
export const Error404 = lazy(() => import('../views/utilities/404'));
export const Profile = lazy(() => import('../views/user/Profile'));

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([{
        path: "/",
        element: (
            <AdminLayout>
                <Suspense>
                    <Outlet />
                </Suspense>
            </AdminLayout>
        ),
        children: [
                { element: <DashboardPage />, index: true },
                { path: 'dashboard', element: <DashboardPage /> },
                { path: 'activities', element: <ActivitiesPage /> },
                { path: 'activities/add', element: <AddActivityPage /> },
                { path: 'activities/:id', element: <ActivityPage /> },
                { path: 'activities/:id/edit', element: <EditActivityPage /> },
                { path: 'boats', element: <BoatsPage /> },
                { path: 'boats/add', element: <AddBoatPage /> },
                { path: 'boats/:id', element: <BoatPage /> },
                { path: 'boats/:id/edit', element: <EditBoatPage /> },
                { path: 'calendar', element: <CalendarPage /> },
                { path: 'crews', element: <CrewPage /> },
                { path: 'crews/add', element: <AddCrew /> },
                { path: 'expenses', element: <ExpensesPage /> },
                { path: 'expenses/add', element: <AddExpensePage /> },
                { path: 'expenses/:id', element: <ExpensePage /> },
                { path: 'expenses/:id/edit', element: <EditExpensePage /> },
                { path: 'category/:name/expenses', element: <CategoryExpensesPage /> },
                { path: 'members', element: <MemberPage /> },
                { path: 'profile', element: <Profile /> },
            ],
        },
        {
            path: 'login',
            element: 
                <Suspense>
                    <LoginPage />
                </Suspense>,
        },
        {
            path: 'register',
            element: 
                <Suspense>
                    <RegisterPage />
                </Suspense>,
        },
        {
            path: '404',
            element: <Error404 />,
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
