import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { useSelector, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getLoginSuccess, boatList, shopList, shopImagesUrl, boatEquipmentList, boatManufacturerList } from "../redux/actions";

import {
    Box,
    CssBaseline,
    Drawer,
    Link,
    Typography,    
} from '@mui/material';

import Header from './header';
import Sidebar from '../components/Sidebar';
import CircularSpinner from '../components/Loading/CircularSpinner';

type State = { a: string };
type AppDispatch = ThunkDispatch<State, any, AnyAction>; 

const drawerWidth = 280;

const AdminLayout = ({ children }) => {
    const navigate = useNavigate();
    const [openNav, setOpenNav] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const { user, account, isLoggedIn, status } = useSelector((state:any) => state.auth);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getLoginSuccess());
    }, [dispatch])

    useEffect(() => {
        if(status === 'succeeded' && !isLoggedIn){
            navigate('/login');
        }
        if(status === 'succeeded' && isLoggedIn){
            dispatch(boatList(account?._id));
            dispatch(boatEquipmentList());
            dispatch(boatManufacturerList());
            dispatch(shopList());
            dispatch(shopImagesUrl());
            setIsLoading(false);
        }
    }, [status, isLoggedIn])

    const renderComponent = (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* <AdminNavbar 
                    user={user} 
                    logout={logout} 
                    handleDrawerToggle={handleDrawerToggle} 
                    drawerWidth={drawerWidth}
                /> */}
                <Header user={user} onOpenNav={() => setOpenNav(true)} />
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="Menu"
                >
                    {/* <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        >
                        <Box sx={{display: 'flex', height: '70px', borderBottom: '1px dashed #393945', alignItems: 'center', pl:2}}>
                            <Link component="button" variant="body2" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} underline="none" color="inherit" onClick={() => navigate(`/dashboard`)}>
                                <img width="35" alt="Sailshappy" src={require('../assets/img/brand/sailshappy_logo.png')} />
                                <Typography component="h6" variant="h6" sx={{color: '#FFF', fontWeight: 600, ml:2}}>Spapp</Typography>
                            </Link>
                        </Box>
                        <Sidebar handleChangeClubSelected={handleChangeClubSelected} logout={logout} clubSelected={user?.clubSelected} />
                    </Drawer> */}
                    <Drawer
                        variant="permanent" 
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { 
                                padding: "0",
                                boxSizing: 'border-box', 
                                borderRadius: "0",
                                width: drawerWidth,
                                boxShadow: "0px 0px 20px 0px rgba(76, 87, 125, 0.02)" 
                            },
                        }}
                        open
                        >
                        <Box sx={{display: 'flex', height: '70px', borderBottom: `1px dashed ${grey[300]}`, alignItems: 'center', pl:2}}>
                            <Link component="button" variant="body2" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} underline="none" color="inherit" onClick={() => navigate(`/dashboard`)}>
                                <img width="35" alt="Sailshappy" src={require('../assets/images/sailshappy_logo.png')} />
                                <Typography component="h6" variant="h6" sx={{fontWeight: 600, ml:2}}>Sailshappy</Typography>
                            </Link>
                        </Box>
                        <Sidebar  />
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, padding: "100px 16px" }}
                >
                    {children}
                </Box>
            </Box>
        </>
    );

    return (
        <>
            {isLoading ? <CircularSpinner /> : renderComponent}
        </>
    );
}

export default AdminLayout;